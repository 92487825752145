<template>
  <section id="interactivities">
    <div v-show="showData">
      <!-- Back -->
      <b-row class="mb-1">
        <b-col md="10">
          <h1>{{ $t("interactivities.create") }}</h1>
          <h5 class="text-primary">
            {{ $t("interactivities.conf") }}
          </h5>
        </b-col>

        <b-col class="d-flex justify-content-end mr-1 m-2 align-items-center">
          <!--  <b-img
            v-if="languageService"
            height="20px"
            width="30px"
            :src="languageFlags(defaultLanguage)"
            class="mr-2"
          /> -->
          <b-button
            variant="danger"
            @click="$router.go(-1)"
          >
            {{
              $t("dataGeneric.goBack")
            }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <h3>
            {{ tit }}
          </h3>
        </b-col>
      </b-row>
      <b-overlay
        variant="transparent"
        :show="show"
      >
        <form-wizard
          v-if="pasoInfo"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="$t('dataGeneric.create')"
          :back-button-text="$t('dataGeneric.goBack')"
          :next-button-text="$t('next')"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <tab-content
            id="pasoInfo"
            :title="$t('contents.categoryData')"
            :before-change="validationInfo"
          >
            <validation-observer
              ref="infoForm"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("interactivities.infoInt") }}
                  </h5>
                  <small class="text-muted">{{
                    $t("interactivities.basica")
                  }}</small>
                </b-col>
              </b-row>

              <b-row>
                <image-element-vue
                  v-if="connect != 'ADV'"
                  :image-origin="image"
                  :image-type="'image'"
                  :label-info="$t('infoImage.int')"
                  :label="$t('Image') + ' ' + $t('miniature')"
                  @saveImage="loadImage"
                />
                <b-col>
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="validationContext"
                        name="name"
                        rules="required|min:3"
                      >
                        <b-form-group
                          :label="$t('dataGeneric.name') + ' *'"
                          :state="validationContext.errors.length > 0 ? false : null"
                        >
                          <b-form-input
                            id="name"
                            v-model="name"
                            maxlength="150"
                            :state="validationContext.errors.length > 0 ? false : null"
                            :placeholder="$t('dataGeneric.name')"
                          />
                          <small class="text-danger">{{ fixMin(validationContext) }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <b-row v-if="connect != 'ADV'">
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="subtitle"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('interactivities.sub') + ' *'"
                          :invalid-feedback="$t('subtitleField')"
                          :state="errors.length > 0 ? false : null"
                        >
                          <b-form-textarea
                            id="name"
                            v-model="subtitle"
                            maxlength="150"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('interactivities.sub')"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="exceptionPlatform()">
                <b-col md="2">
                  <b-form-group :label="$t('hasLottie')">
                    <b-form-checkbox
                      v-model="hasLottie"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col md="10">
                  <b-form-group
                    v-if="hasLottie"
                    :label="$t('lottieDesign')"
                  >
                    <b-form-select
                      v-model="lottieDesign"
                      :options="[
                        { value: 'vertical_derecha', text: $t('vertical_derecha') },
                        { value: 'horizontal_derecha', text: $t('horizontal_derecha') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('startType')"
                  >
                    <b-form-select
                      v-model="timeType"
                      :options="[
                        { value: 'REL', text: $t('interactivities.rel') },
                        { value: 'ABS', text: $t('interactivities.abs') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    v-if="timeType == 'ABS'"
                    :label="$t('interactivities.abs')"
                  >
                    <validation-provider
                      v-slot="{}"
                      name="fDate"
                      rules="required"
                    >
                      <b-form-datepicker
                        id="fDate"
                        v-model="date"
                        class="mb-1"
                        :min="min"
                        locale="en"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{}"
                      name="fTime"
                      rules="required"
                    >
                      <b-form-timepicker
                        id="fTime"
                        v-model="hour"
                        locale="en"
                      />
                    </validation-provider>
                  </b-form-group>
                  <!-- TODO: Sacar tiempo del recurso -->
                  <b-form-group
                    v-else
                    :label="$t('startTime')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="timeStart"
                      rules="timeDuration"
                    >
                      <!-- #default="{ errors }" -->
                      <b-row
                        cols="12"
                        class="align-items-center "
                      >
                        <b-col>
                          <b-form-timepicker
                            id="timeStart"
                            v-model="timeStart"
                            show-seconds
                            locale="es"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-col>
                      </b-row>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <b-row>
                        <b-col>
                          <div class="tagDur mt-4">
                            {{ $t("interactivities.init") }}
                            {{ timeToSecond(timeStart, 0) }}
                          </div>
                        </b-col>
                      </b-row>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
            v-if="pasoDestino"
            id="pasoDestino"
            :title="$t('finalRes')"
            :before-change="validationDestino"
          >
            <validation-observer
              v-if="str != 'PUSH' && str != 'EMAIL'"
              ref="destino"
              tag="form"
            >
              <validation-provider
                v-slot="validationContext"
                name="resourceName"
                rules="required"
              >
                <b-row class="m-1">
                  {{ $t('interactResource') }}
                </b-row>
                <b-row>
                  <b-col @click="showModalResource()">
                    <b-form-input
                      id="resourceName"
                      v-model="resourceName"
                      class="ml-1"
                      disabled
                      :state="validationContext.errors.length > 0 ? false : null"
                    />
                  </b-col>
                  <b-col md="2">
                    <b-button @click="showModalResource()">
                      {{
                        $t("createContent.sel")
                      }}
                    </b-button>
                  </b-col>
                </b-row>
                <small class="text-danger ml-1">{{ fixRequired(validationContext) }}</small>
              </validation-provider>
              <validation-provider
                v-if="videotype != 'DIR' && tipo == 'VID' && str != 'BANN'"
                v-slot="{ errors }"
                name="secondFile"
                rules="required"
              >
                <!-- TODO: Sacar tiempo del recurso -->

                <b-row
                  cols="12"
                  class="align-items-center mt-2"
                >
                  <b-col class="m-1">
                    <b-form-timepicker
                      id="secondFile"
                      v-model="secondFile"
                      show-seconds
                      :state="errors.length > 0 ? false : null"
                      locale="es"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-col>
                </b-row>
                <b-row class="m-1 justify-content-center">
                  <b-col>
                    <div class="tagDur mt-1">
                      {{ $t("interactivities.segVid") }}
                      {{ timeToSecond(secondFile, 1) }}'s
                    </div>
                  </b-col>
                </b-row>
              </validation-provider>
            </validation-observer>
            <validation-observer
              v-else
              ref="destino"
              tag="form"
            >
              <div v-if="str == 'PUSH'">
                <h5 class="mb-2">
                  {{ $t("interactivities.push") }}
                </h5>
                <validation-provider
                  v-slot="validationContext"
                  name="urlDestino"
                  rules="required|url"
                >
                  <b-form-group
                    :label="$t('interactivities.url') + ' *'"
                    :state="validationContext.errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="urlDestino"
                      v-model="urlPush"
                      maxlength="150"
                      :placeholder="$t('interactivities.url')"
                      :state="validationContext.errors.length > 0 ? false : null"
                      autocomplete="new-password"
                    />
                    <small class="text-danger ">{{ fixUrl(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="validationContext"
                  name="messagePush"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('interactivities.mess') + ' *'"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="messagePush"
                      v-model="messagePush"
                      class=""
                      maxlength="150"
                      :placeholder="$t('interactivities.mess')"
                      :state="validationContext.errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <b-form-checkbox
                  v-model="isAuto"
                  switch
                  class="m-1"
                >
                  {{ $t("interactivities.sendAut") }}
                </b-form-checkbox>
                <small
                  v-if="isAuto"
                  class="text-primary"
                >{{
                  $t("interactivities.dato")
                }}</small>
              </div>
              <div v-else>
                <h5>{{ $t("interactivities.email") }}</h5>

                <validation-provider
                  v-slot="validationContext"
                  name="subjectMail"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('interactivities.asuntoEmail') + ' *'"
                    :state="validationContext.errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="subjectMail"
                      v-model="subjectMail"
                      maxlength="250"
                      :placeholder="$t('interactivities.asuntoEmail')"
                      :state="validationContext.errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ fixEmail(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="bodyMail"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('interactivities.bodyMess') + ' *'"
                    :state="errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <quill-editor
                      v-model="bodyMail"
                      :options="snowOptions"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <div class="m-2">
                  <b-form-checkbox
                    v-model="pdf"
                    switch
                    class="m-1"
                  >
                    {{ $t("interactivities.adjuntar") }}
                  </b-form-checkbox>
                  <b-form-file
                    v-if="pdf"
                    v-model="fileMail"
                    accept=".pdf"
                  />
                </div>
              </div>
            </validation-observer>
          </tab-content>
          <tab-content
            v-if="pasoDuration"
            id="pasoDuration"
            :title="$t('contents.Duration')"
            :before-change="validationDuration"
          >
            <validation-observer
              ref="duration"
              tag="form"
            >
              <b-row class="m-2">
                <h5>{{ $t("interactivities.dur") }}</h5>
              </b-row>
              <validation-provider
                v-slot="{ errors }"
                name="tagDuration"
                rules="timeDuration"
              >
                <b-row
                  cols="12"
                  class="align-items-center mt-2"
                >
                  <b-col class="m-1">
                    <b-form-timepicker
                      id="tagDuration"
                      v-model="tagDuration"
                      show-seconds
                      locale="es"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
                <b-row
                  class="m-1"
                  style="justify-content: center"
                >
                  <div class="tagDur mt-1">
                    {{ $t("interactivities.duration") }}
                    {{ timeToSecond(tagDuration, 0) }}'s
                  </div>
                </b-row>
              </validation-provider>
            </validation-observer>
          </tab-content>
        </form-wizard>
        <form-wizard
          v-else
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="$t('dataGeneric.create')"
          :back-button-text="$t('dataGeneric.goBack')"
          :next-button-text="$t('next')"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <tab-content
            v-if="pasoDestino"
            id="pasoDestino"
            :title="$t('finalRes')"
            :before-change="validationDestino"
          >
            <validation-observer
              v-if="str != 'PUSH' && str != 'EMAIL'"
              ref="destino"
              tag="form"
            >
              <b-row v-if="connect == 'GOF'">
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('dataGeneric.name') + ' *'"
                      :invalid-feedback="$t('required')"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                        maxlength="150"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('dataGeneric.name')"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <validation-provider
                v-slot="{ errors }"
                name="resourceName"
                rules="required"
                class="row"
              >
                <b-col>
                  <b-form-group
                    :label="$t('interactResource') + ' *'"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="resourceName"
                      v-model="resourceName"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="2"
                  class="d-flex align-items-center"
                >
                  <b-button @click="showModalResource()">
                    {{
                      $t("createContent.sel")
                    }}
                  </b-button>
                </b-col>
              </validation-provider>
              <validation-provider
                v-if="videotype != 'DIR' && str != 'BANN'"
                v-slot="{ errors }"
                name="secondFile"
                rules="required"
              >
                <!-- TODO: Sacar tiempo del recurso -->
                <b-row
                  cols="12"
                  class="align-items-center mt-2"
                >
                  <b-col class="m-1">
                    <b-form-timepicker
                      id="secondFile"
                      v-model="secondFile"
                      :state="errors.length > 0 ? false : null"
                      show-seconds
                      locale="es"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-col>
                </b-row>
                <b-row class="m-1 justify-content-center">
                  <b-col>
                    <div class="tagDur mt-1">
                      {{ $t("interactivities.segVid") }}
                      {{ timeToSecond(secondFile, 1) }}'s
                    </div>
                  </b-col>
                </b-row>
              </validation-provider>
            </validation-observer>
            <validation-observer
              v-else
              ref="destino"
              tag="form"
            >
              <div v-if="str == 'PUSH'">
                <h5>{{ $t("interactivities.push") }}</h5>
                <validation-provider
                  v-slot="{ errors }"
                  name="url"
                  rules="required"
                >
                  <b-row>
                    <b-form-input
                      id="url"
                      v-model="urlPush"
                      class="m-2"
                      maxlength="150"
                      :placeholder="$t('interactivities.url')"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="messagePush"
                  rules="required"
                >
                  <b-row>
                    <b-form-input
                      id="messagePush"
                      v-model="messagePush"
                      class="m-2"
                      maxlength="150"
                      :placeholder="$t('interactivities.mess')"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-form-checkbox
                  v-model="isAuto"
                  switch
                  class="m-1"
                >
                  {{ $t("interactivities.sendAut") }}
                </b-form-checkbox>
                <small
                  v-if="isAuto"
                  class="text-primary"
                >{{
                  $t("interactivities.dato")
                }}</small>
              </div>
              <div v-else>
                <h5>{{ $t("interactivities.email") }}</h5>

                <validation-provider
                  v-slot="{ errors }"
                  name="subjectMail"
                  rules="required"
                >
                  <b-row class="m-1">
                    <b-col>
                      <label for="">{{
                        $t("interactivities.asuntoEmail")
                      }}</label>

                      <b-form-input
                        id="subjectMail"
                        v-model="subjectMail"
                        maxlength="250"
                        :placeholder="$t('interactivities.asuntoEmail')"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                  </b-row>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="bodyMail"
                  rules="required"
                >
                  <b-row class="m-1">
                    <b-col>
                      <label for="">{{ $t('interactivities.bodyMess') }}</label>
                      <quill-editor
                        v-model="bodyMail"
                        :options="snowOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                  </b-row>
                </validation-provider>
                <div class="m-2">
                  <b-form-checkbox
                    v-model="pdf"
                    switch
                    class="m-1"
                  >
                    {{ $t("interactivities.adjuntar") }}
                  </b-form-checkbox>
                  <b-form-file
                    v-if="pdf"
                    v-model="fileMail"
                    accept=".pdf"
                  />
                </div>
              </div>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-overlay>

      <b-modal
        ref="modal-resource"
        size="xl"
        hide-footer
        :title="$t('createContent.sel')"
      >
        <div class="d-block text-center">
          <resources-selector
            :msg="tipo"
            :sponsor="isSponsor"
            :image="str == 'BANN' ? true : false"
            @data="seleccionarRecurso"
          />
        </div>
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          {{ $t("dataGeneric.close") }}
        </b-button>
      </b-modal>
    </div>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BFormFile,
  BFormDatepicker,
  BFormTimepicker,
  BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import { utf8ToB64, messageError, base64Encode } from '@/store/functions'
import languageFlags from '@/@core/utils/languageFlags'
import i18n from '@/libs/i18n'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

import { quillEditor } from 'vue-quill-editor'

// eslint-disable-next-line import/no-extraneous-dependencies
import * as Quill from 'quill'
import ImageElementVue from '@/views/components/ImageElement.vue'
import ResourcesSelector from '../../common/ResourcesSelector.vue'

extend('timeDuration', value => {
  const res = value.split(':')
  const hora = res[0] * 3600
  const minuto = res[1] * 60
  const seg = res[2]
  const tiempo = parseInt(hora, 10) + parseInt(minuto, 10) + parseInt(seg, 10)

  if (tiempo >= 10) {
    return true
  }
  return i18n.t('valueSu10')
})

const alignClass = Quill.import('attributors/style/align')
const backgroundClass = Quill.import('attributors/style/background')
const colorClass = Quill.import('attributors/style/color')
const directionClass = Quill.import('attributors/style/direction')
const fontClass = Quill.import('attributors/style/font')
const sizeClass = Quill.import('attributors/style/size')

Quill.register(alignClass, true)
Quill.register(backgroundClass, true)
Quill.register(colorClass, true)
Quill.register(directionClass, true)
Quill.register(fontClass, true)
Quill.register(sizeClass, true)
export default {
  components: {
    BOverlay,
    BFormSelect,
    ResourcesSelector,
    BFormCheckbox,
    BFormFile,
    BFormDatepicker,
    BFormTimepicker,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    ImageElementVue,
    quillEditor,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      required,
      hasLottie: false,
      lottieDesign: 'vertical_derecha',
      str: null,
      tit: null,
      tagDuration: '00:00:10',
      timeStart: '00:00:10',
      subtitle: null,
      name: null,
      comment: null,
      image: null,
      active: null,
      visible: null,
      content: null,
      isSponsor: false,
      userData: getUserData(),
      imageDataSrc: null,
      interactivitie: null,
      fecha: null,
      min: null,
      max: null,
      tipo: null,
      defaultLanguage: '',
      languageService: false,
      resourceUrl: null,
      resourceName: null,
      resourceId: null,
      videotype: null,
      pasoInfo: false,
      pasoDestino: false,
      showData: false,
      pasoDuration: false,
      pdf: false,
      isAuto: false,
      bodyMail: null,
      subjectMail: null,
      fileMail: null,
      messagePush: null,
      urlPush: null,
      secondFile: '00:00:00',
      date: null,
      hour: null,
      timeType: 'REL',
      resourceStart: null,
      connect: 'GOT',
      show: false,
      typeShop: '',
      snowOptions: {
        theme: 'snow',
      },
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        snowOptions: {
          theme: 'snow',
        },

        placeholder: this.$t('interactivities.body'),
      },
    }
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataSrc = value
            })
            .catch(() => {
              this.imageDataSrc = null
            })
        } else {
          this.imageDataSrc = null
        }
      }
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }

    this.getDefaultLanguage()
  },
  methods: {
    languageFlags,
    fixMin(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('purchases.nameFormValid')
      }
      if (keyName === 'min') {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    fixRequired(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('resourceName')
      }
      return ''
    },
    fixUrl(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'url') {
        return this.$t('tabUser.urlValid')
      }
      if (keyName === 'required') {
        return this.$t('resourceName')
      }
      return ''
    },
    fixEmail(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'email') {
        return this.$t('emailValidField')
      }
      if (keyName === 'required') {
        return this.$t('emailField')
      }
      return ''
    },

    getStr() {
      this.str = this.$route.params.str
      switch (this.str) {
        case 'QUEST':
          this.tit = this.$t('interactivities.quest')
          this.connect = 'GOT'
          this.tipo = 'TST'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'INFO':
          this.tit = this.$t('resources.moreInfo')
          this.connect = 'GOT'
          this.tipo = 'PLN'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'DSHOP':
          this.tit = this.$t('interactivities.shop')
          if (this.typeShop === 'INT') {
            this.tipo = 'BUY'
          } else if (this.typeShop === 'A2C') {
            this.tipo = 'A2B'
          } else {
            // this.tipo = 'A2B|BUY'
            this.tipo = 'BUY'
          }
          this.connect = 'GOT'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'ADCART':
          this.tit = this.$t('interactivities.add')
          if (this.typeShop === 'INT') {
            this.tipo = 'CAR'
          } else if (this.typeShop === 'A2C') {
            this.tipo = 'A2C'
          } else {
            // this.tipo = 'A2C|CAR'
            this.tipo = 'CAR'
          }
          this.connect = 'GOT'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'DEEP':
          this.tit = 'Deeplinking'
          this.tipo = 'DEE'
          this.connect = 'GOT'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'VID':
          this.tit = this.$t('videoInt')

          this.tipo = 'VID'
          this.connect = 'GOT'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break

        case 'EMAIL':
          this.tit = 'E-MAIL'

          this.connect = 'EMA'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'PUSH':
          this.tit = 'PUSH'

          this.connect = 'PUS'
          this.pasoInfo = true
          this.pasoDestino = true
          this.pasoDuration = true
          break
        case 'FVID':
          this.tit = this.$t('interactivities.fVideo')

          this.tipo = 'VID'
          this.connect = 'GOF'
          this.pasoDestino = true
          break
        case 'BANN':
          this.tit = 'Banner'
          this.tipo = 'VID'
          this.connect = 'ADV'
          this.pasoInfo = true
          this.pasoDestino = true

          this.pasoDuration = true
          break
        default:
          break
      }
    },
    fechaDir() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      this.min = new Date(today)
    },
    validationType() {
      return new Promise((resolve, reject) => {
        this.$refs.infoInteractivitie.validate().then(() => {
          if (this.interactivitie) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
                 typeShop
             }
         }
         `,
        })
        .then(res => {
          const { availableLanguages, typeShop } = res.data.data.client
          this.typeShop = typeShop
          try {
            const arr = availableLanguages.split('|')
            this.languageService = arr.length > 1
            // eslint-disable-next-line no-empty
          } catch (error) { }
          this.defaultLanguage = res.data.data.client.defaultLanguage

          this.getStr()
          this.fileContent()
          this.fechaDir()
        })
        .catch(() => {
        })
    },
    formSubmitted() {
      this.show = true

      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }

      const content = this.$route.params.id
      const client = getUserData().profile.client.id

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      /*  if (this.videotype === 'DIR') {
        this.timeType = 'ABS'
        this.timeStart = null
      } else {
        this.timeType = 'REL'
        this.date = null
        this.hour = null
      } */
      const query = `
                 mutation
                   ($bodyMail: String, $messagePush: String, $subjectMail: String, $name: String!, $description: String)
                  {
                    createInteractivities(input:{                      
                      client:"${client}",
                      content:"${content}",
                      isActive: false,
                      isVisible: true,
                      connect: ${this.connect},
                      hasLottie: ${this.hasLottie},
                      ${this.hasLottie ? `lottieDesign: "${this.lottieDesign}"` : ''}
                      ${this.resourceStart !== null ? `resourceStart: "${this.resourceStart}",` : ''}
                      ${this.timeStart !== null ? `second: ${this.timeToSecond(this.timeStart, 0)},` : ''}
                      timeType: ${this.timeType},
                      ${this.date !== null ? `date:"${this.date}",` : ''}
                      ${this.hour !== null ? `hour: "${this.hour}",` : ''}
                      ${this.resourceId !== null ? `resourceEnd: "${this.resourceId}",` : ''}
                      ${this.secondFile !== null ? `secondFile: ${this.timeToSecond(this.secondFile, 1)},` : ''}
                      ${this.tagDuration !== null ? `time: ${this.timeToSecond(this.tagDuration, 0)},` : ''}
                      typePush: WEB,
                      isAuto: ${this.isAuto},
                      ${this.urlPush == null ? '' : `urlPush:"${this.urlPush}",`}
                      name: $name,
                      messagePush: $messagePush,
                      subjectMail: $subjectMail,
                      description: $description,
                      bodyMail: $bodyMail,                     
                      }) {
                      interactivity{
                        name
                        client{
                          name
                        }
                        content{
                          name
                        }
                        description
                        isActive
                        isVisible
                        time
                        resourceStart{
                          name
                        }
                        resourceEnd{
                          name
                        }
                      }
                    }
                  }
`
      const variables = {
        bodyMail: this.bodyMail != null ? this.bodyMail : '',
        messagePush: this.messagePush != null ? this.messagePush : '',
        subjectMail: this.subjectMail != null ? this.subjectMail : '',
        name: this.name == null ? `${this.$route.params.str}_${content} ` : this.name,
        description: this.subtitle,
      }

      data.append('variables', JSON.stringify(variables))

      data.append('query', query)

      if (this.image != null) data.append('image', this.image)

      if (this.pdf) data.append('file_mail', this.fileMail)

      if (this.resourceId != null) {
        const resource = utf8ToB64(`id:${this.resourceId} `)
        axios
          .post('', {
            query: `
          {
            allContents(id: "${content}"){
            edges {
              node {
                id
                contentResources(resource: "${resource}"){
                  totalCount
                  edges {
                    node {
                      id
                      content
                              resource {
                        id
                      }

                    }
                  }
                }
              }
            }
            }
          }
        `,
          }, { headers })
          .then(result => {
            messageError(result, this)

            if (
              result.data.data.allContents.edges[0].node.contentResources
                .totalCount === 0
            ) {
              axios
                .post('', {
                  query: `
                  mutation{
                  createContentsResource(input: { content: "${content}", resource: "${this.resourceId}" }){
                                    contentResource2{
                      id
                      content
                                      resource{
                        id
                        name
                      }
                    }
                  }
                }
                `,
                })
                .then(response => {
                  messageError(response, this)

                  axios.post('', data, { headers }, config).then(res => {
                    messageError(res, this)

                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: this.$t('success'),
                        icon: 'EditIcon',
                        variant: 'success',
                      },
                    })
                    if (res.status === 200) {
                      this.show = false

                      this.$router.push({
                        name: 'interactivities-video',
                        params: { id: content },
                      })
                    }

                    this.show = false
                  })
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                })
            } else {
              axios.post('', data, { headers }, config).then(res => {
                messageError(res, this)

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('success'),
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                if (res.status === 200) {
                  this.show = false

                  this.$router.push({
                    name: 'interactivities-video',
                    params: { id: content },
                  })
                }

                this.show = false
              })
            }
          })
          .catch(() => { })
      } else {
        axios.post('', data, { headers }, config).then(res => {
          messageError(res, this)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          if (res.status === 200) {
            this.show = false

            this.$router.push({
              name: 'interactivities-video',
              params: { id: content },
            })
          }
          this.show = false
        })
      }
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'image':
          this.image = data
          break

        default:
          break
      }
    },
    chooseImage() {
      document.getElementById('fileInput').click()
    },
    fileContent() {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
          query{
            allContents(id: "${id}"){
              edges {
                node {
                  id
                  name
                  creator {
                    id
                  }
                  contentResources(isInitial: true){
                    edges{
                      node{
                        resource{
                          id
                          type
                          videotype
                          directUrl
                          file{
                            fileUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        `,
        })
        .then(response => {
          messageError(response, this)
          // Sponsor role. Uncomment it to restrict access again
          /* let authorized = false
          if (this.isSponsor) {
            if (response.data.data.allContents.edges[0].node.creator !== null) {
              if (response.data.data.allContents.edges[0].node.creator.id === this.userData.id) { authorized = true }
            }
            if (!authorized) {
              this.$router.push({ name: 'misc-not-authorized' })
            }
          } */
          this.videotype = response.data.data.allContents.edges[0].node.contentResources.edges[0].node.resource.videotype

          this.resourceStart = this.$route.params.resource ? this.$route.params.resource
            : response.data.data.allContents.edges[0].node.contentResources.edges[0].node.resource.id
          try {
            this.content = response.data.data.allContents.edges[0].node.contentResources.edges[0].node.resource.file.fileUrl
          } catch (error) {
            // console.log(error);
          }
          // Sponsor role. Uncomment it to restrict access again
          // if (!authorized && this.isSponsor) { this.showData = false } else { this.showData = true }
          this.showData = true // Remove this
        })
    },
    exceptionPlatform() {
      const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
      if (mode !== 'production') {
        return true
      }
      return this.isPlatformAvailable(this.userData.profile.client.name)
    },
    isPlatformAvailable(idPlatform) {
      const allowedPlatforms = [
        'fcbarcelona', 'fcbvideo', 'psoeplay', 'breakonsponsorship', 'breakon',
        'juanamartin', 'dipucordoba', 'jcf', 'cca', 'figc', 'madcup', 'mecctv',
        'smartplatino', 'genial_art', 'canalsur',
      ]

      // Verifica si la plataforma está en el array
      return allowedPlatforms.includes(idPlatform)
    },
    showModalResource() {
      this.$refs['modal-resource'].show()
    },
    hideModal() {
      this.$refs['modal-resource'].hide()
    },
    validationInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    seleccionarRecurso(data) {
      this.hideModal()
      try {
        const [resourceId, resourceName, resourceUrl] = data
        this.resourceId = resourceId
        this.resourceName = resourceName
        this.resourceUrl = resourceUrl
      } catch (error) {
        const [resourceId, resourceName] = data
        this.resourceId = resourceId
        this.resourceName = resourceName
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.rec'),
          icon: 'BellIcon',
          text: this.resourceName,
          variant: 'success',
        },
      })
    },
    goToBackPage(num) {
      const contentId = this.$route.params.id

      this.$router.push({
        name: num === 0 ? 'interactivities-create' : 'interactivities-video',
        params: { id: contentId },
      })
    },

    validationDestino() {
      return new Promise((resolve, reject) => {
        this.$refs.destino.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationDuration() {
      return new Promise((resolve, reject) => {
        this.$refs.duration.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    timeToSecond(value, type) {
      switch (type) {
        case 0:
          try {
            const res = value.split(':')
            const hora = res[0] * 3600
            const minuto = res[1] * 60
            const seg = res[2]
            const tiempo = parseInt(hora, 10) + parseInt(minuto, 10) + parseInt(seg, 10)
            return tiempo < 10 ? 10 : tiempo
          } catch (error) {
            return ''
          }
        case 1:
          try {
            const res = value.split(':')
            const hora = res[0] * 3600
            const minuto = res[1] * 60
            const seg = res[2]
            const tiempo = parseInt(hora, 10) + parseInt(minuto, 10) + parseInt(seg, 10)
            return tiempo
          } catch (error) {
            return ''
          }

        default:
          break
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.slideTime {
  display: flex;
  justify-content: space-between;
}

.tagDur {
  text-align-last: center;
}

.modal {
  overflow-y: auto;
}

/*
Full screen Modal
*/
.fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}
</style>
